import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const HistoryListStyles = styled.div``

const HistorySingleStyles = styled.div``

const HistorySingleEntry = ({ entry }) => (
  <HistorySingleStyles>
    <Link to={entry.slug.current}>
      <h3>
        {entry.year} - {entry.title}
      </h3>
    </Link>
  </HistorySingleStyles>
)

export default function HistoryList({ history }) {
  return (
    <HistoryListStyles>
      {history.map(entry => (
        <HistorySingleEntry key={entry.id} entry={entry} />
      ))}
    </HistoryListStyles>
  )
}
