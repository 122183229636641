import { graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'

import HistoryList from '../../../components/lists/HistoryList'

const MainContentStyles = styled.div``

export default function HistoryByYear({ data }) {
  const history = data.history.nodes

  return (
    <MainContentStyles className="content-width">
      <h1 className="title">History By Year</h1>
      <div className="history-list">
        <HistoryList history={history} />
      </div>
    </MainContentStyles>
  )
}

export const HistoryQuery = graphql`
  query HistoryByYearQuery {
    history: allSanityHistory {
      nodes {
        id
        title
        slug {
          current
        }
        year
      }
    }
  }
`
